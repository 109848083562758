import React from 'react'
import './projects.scss';
import meetup from '../../assets/images/projects/meetup.jpg';
import pigame from '../../assets/images/projects/pigame.PNG'
import githubFinder from '../../assets/images/projects/github-finder.PNG'
import forkify from '../../assets/images/projects/forkify.PNG'
import leetcode from '../../assets/images/projects/leetcode.PNG'
import budgety from '../../assets/images/projects/budgety.PNG'
import smartcity from '../../assets/images/projects/smartcity.jpg'
import tcetkiosk from '../../assets/images/projects/tcetkiosk.PNG'
import dip from '../../assets/images/projects/dip.PNG'


function Project() {

    return (
        <div>
            <div className="projects" id="projects">
                <div className="in-middle_projects">
                    <h3 className="section-title">Projects</h3>
                    <div className="centered line"></div>
                    <div className="wrapper">

                        <div className="pcard">
                            <input type="checkbox" id="card9" className="more" aria-hidden="true" />
                            <div className="content">
                                <div className="front" style={{
                                    backgroundImage: `url(${dip})`
                                }}>
                                    <div className="inner">

                                        <div><h2><a href="https://dhruvdh.github.io/dip_app/" target="_blank" rel="noopener noreferrer" alt="Digital Image Processing">Image Processing</a></h2><h3 className="sub-title">Web Application</h3></div>
                                        <label htmlFor="card9" className="button" aria-hidden="true">
                                            Details
                                </label>
                                    </div>
                                </div>
                                <div className="back">
                                    <div className="inner">
                                        <div className="info">
                                            <b>Tags: Vue.js, Vuex, Git, Webpack, Rust, webAssembly, Python</b>
                                        </div>
                                        <div className="description">
                                            <p>
                                                A collection of educational, interactive demonstrations for common digital image processing algorithms written using modern web framework and WebAssembly. Additionally, functionality includes capability to parse and point out errors in each assignment for the students.
                                    </p>
                                        </div>
                                        <div className="project-header">
                                            <div className="location" style={{ display: "flex", marginBottom: "2rem" }}>
                                                {/* eslint-disable-next-line */}
                                                <a href="https://dhruvdh.github.io/dip_app/" target="_blank" rel="noopener noreferrer" style={{ color: "#000000", fontSize: "30px", paddingLeft: "1rem" }}><i className="fa fa-external-link" aria-hidden="true"></i></a>
                                            </div>

                                        </div>
                                        <label htmlFor="card9" className="button return" aria-hidden="true">
                                            <i className="fa fa-arrow-left"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="pcard">
                            <input type="checkbox" id="card1" className="more" aria-hidden="true" />
                            <div className="content">
                                <div className="front" style={{
                                    backgroundImage: `url(${meetup})`
                                }}>
                                    <div className="inner">
                                        <h2><a href="https://github.com/shreeyag11/women-empowerment" alt="Women Empowerment">Women Empowerment</a></h2>
                                        <label htmlFor="card1" className="button" aria-hidden="true">
                                            Details
                                </label>

                                    </div>
                                </div>
                                <div className="back">
                                    <div className="inner">
                                        <div className="info">
                                            <b>Tags: HTML5, CSS, JavaScript, NodeJS, MongoDB</b>
                                        </div>
                                        <div className="description">
                                            <p>Developed web-based artifacts for a meetup-application to render women empowerment events in the form of connections,
                                            user can login and respond to respective events as well as create a new event. This is implemented
                                            on NodeJS, HTML5 for front-end, dynamic content to website is stored in MongoDB and Mongoose is the
                                           npm API used to serve requests.</p>
                                        </div>
                                        <div className="project-header">
                                            <div className="location" style={{ float: "right" }}>
                                                {/* eslint-disable-next-line */}
                                                <a href="https://github.com/shreeyag11/women-empowerment" target="_blank" rel="noopener noreferrer" style={{ color: "#000000", fontSize: "30px" }}><i className="fa fa-github" aria-hidden="true"></i></a>

                                            </div>

                                        </div>
                                        <label htmlFor="card1" className="button return" aria-hidden="true">
                                            <i className="fa fa-arrow-left"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pcard">
                            <input type="checkbox" id="card3" className="more" aria-hidden="true" />
                            <div className="content">
                                <div className="front" style={{
                                    backgroundImage: `url(${githubFinder})`
                                }}>
                                    <div className="inner">
                                        <h2><a href="https://github-profile-finder05.netlify.app/" alt="Github finder">Github Finder</a></h2>
                                        <label htmlFor="card3" className="button" aria-hidden="true">
                                            Details
                                </label>
                                    </div>
                                </div>
                                <div className="back">
                                    <div className="inner">
                                        <div className="info">
                                            <b>Tags: React, Netlify, Hooks, Context, GitHub API</b>
                                        </div>
                                        <div className="description">
                                            <p>A nicer look at your GitHub profile and repositories.
                                            It searches for all the Github users using the text inputted by the user in the form. It returns all the users matching the string in a grid format.
                                            Clicking on the more tab takes the user to a custom made page with the vital stats.
                                    </p>
                                        </div>
                                        <div className="project-header">
                                            <div className="location" style={{ display: "flex", marginBottom: "2rem" }}>
                                                {/* eslint-disable-next-line */}
                                                <a href="https://github.com/shreeyag11/github-finder" target="_blank" rel="noopener noreferrer" style={{ color: "#000000", fontSize: "30px" }}><i className="fa fa-github" aria-hidden="true"></i></a>
                                                {/* eslint-disable-next-line */}
                                                <a href="https://github-profile-finder05.netlify.app/" target="_blank" rel="noopener noreferrer" style={{ color: "#000000", fontSize: "30px", paddingLeft: "1rem" }}><i className="fa fa-external-link" aria-hidden="true"></i></a>
                                            </div>

                                        </div>
                                        <label htmlFor="card3" className="button return" aria-hidden="true">
                                            <i className="fa fa-arrow-left"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="pcard">
                            <input type="checkbox" id="card4" className="more" aria-hidden="true" />
                            <div className="content">
                                <div className="front" style={{
                                    backgroundImage: `url(${forkify})`
                                }}>
                                    <div className="inner">
                                        <h2><a href="https://shreeyag11.github.io/forkify/dist/" alt="Forkify">Forkify</a></h2>
                                        <label htmlFor="card4" className="button" aria-hidden="true">
                                            Details
                        </label>
                                    </div>
                                </div>
                                <div className="back">
                                    <div className="inner">
                                        <div className="info">
                                            <b> Tags: HTML5, CSS, Vanilla JavaScript</b>
                                        </div>
                                        <div className="description">
                                            <p>A recipe searching application in which you can search over 1,000,000 recipes.
                                            The recipes actually come from a real world API that I have used.
                                            You can search a recipe and the recipe again loads it from the external API.
                                            You can also adjust the number of servings and the amount of ingredients then automatically adjusts.
                                            You can then add these ingredients to a shopping list.
                                            You can adjust the number of any ingredient in the shopping list or delete any particular item.
                                            You can save these recipes in your favorites by hitting the like button on the recipe page.
                                    </p>
                                        </div>
                                        <div className="project-header">
                                            <div className="location" style={{ display: "flex", marginBottom: "2rem" }}>
                                                {/* eslint-disable-next-line */}
                                                <a href="https://github.com/shreeyag11/forkify" target="_blank" rel="noopener noreferrer" style={{ color: "#000000", fontSize: "30px" }}><i className="fa fa-github" aria-hidden="true"></i></a>
                                                {/* eslint-disable-next-line */}
                                                <a href="https://shreeyag11.github.io/forkify/dist/" target="_blank" rel="noopener noreferrer" style={{ color: "#000000", fontSize: "30px", paddingLeft: "1rem" }}><i className="fa fa-external-link" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                        <label htmlFor="card4" className="button return" aria-hidden="true">
                                            <i className="fa fa-arrow-left"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pcard">
                            <input type="checkbox" id="card5" className="more" aria-hidden="true" />
                            <div className="content">
                                <div className="front" style={{
                                    backgroundImage: `url(${budgety})`
                                }}>
                                    <div className="inner">
                                        <h2><a href="https://shreeyag11.github.io/budgety/" alt="Budgety">Budgety</a></h2>
                                        <label htmlFor="card5" className="button" aria-hidden="true">
                                            Details
                        </label>
                                    </div>
                                </div>
                                <div className="back">
                                    <div className="inner">
                                        <div className="info">
                                            <b> Tags: HTML5, CSS, Vanilla JavaScript</b>
                                        </div>
                                        <div className="description">
                                            <p>Budgety is a simple budget tracker app.
                                            It allows user to input incomes &amp; expenses and see how the complete budget changes due to custom insertions.
                                            It keep tracks of percentage expense done with respect to the income.

                                    </p>
                                        </div>
                                        <div className="project-header">
                                            <div className="location" style={{ display: "flex", marginBottom: "2rem" }}>
                                                {/* eslint-disable-next-line */}
                                                <a href="https://github.com/shreeyag11/budgety" target="_blank" rel="noopener noreferrer" style={{ color: "#000000", fontSize: "30px" }}><i className="fa fa-github" aria-hidden="true"></i></a>
                                                {/* eslint-disable-next-line */}
                                                <a href="https://shreeyag11.github.io/budgety/" target="_blank" rel="noopener noreferrer" style={{ color: "#000000", fontSize: "30px", paddingLeft: "1rem" }}><i className="fa fa-external-link" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                        <label htmlFor="card5" className="button return" aria-hidden="true">
                                            <i className="fa fa-arrow-left"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="pcard">
                            <input type="checkbox" id="card2" className="more" aria-hidden="true" />
                            <div className="content">
                                <div className="front" style={{
                                    backgroundImage: `url(${pigame})`
                                }}>
                                    <div className="inner">
                                        <h2><a href="https://shreeyag11.github.io/pig-game/" alt="Pig game">Pig game</a></h2>
                                        <label htmlFor="card2" className="button" aria-hidden="true">
                                            Details
                                </label>
                                    </div>
                                </div>
                                <div className="back">
                                    <div className="inner">
                                        <div className="info">
                                            <b> Tags: HTML5, CSS, Vanilla JavaScript</b>
                                        </div>
                                        <div className="description">
                                            <p>Designed a Pig Game, commonly called Pig Dice Game which is a JavaScript based game.
                                                It can be used by someone in their free time for relaxation. So ideally you can also call it a stressbuster. <span role="img" aria-label="smile">&#128513;</span>

                                            </p>
                                        </div>
                                        <div className="project-header">
                                            <div className="location" style={{ display: "flex", marginBottom: "2rem" }}>
                                                {/* eslint-disable-next-line */}
                                                <a href="https://github.com/shreeyag11/pig-game" target="_blank" rel="noopener noreferrer" style={{ color: "#000000", fontSize: "30px" }}><i className="fa fa-github" aria-hidden="true"></i></a>
                                                {/* eslint-disable-next-line */}
                                                <a href="https://shreeyag11.github.io/pig-game/" target="_blank" rel="noopener noreferrer" style={{ color: "#000000", fontSize: "30px", paddingLeft: "1rem" }}><i className="fa fa-external-link" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                        <label htmlFor="card2" className="button return" aria-hidden="true">
                                            <i className="fa fa-arrow-left"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="pcard">
                            <input type="checkbox" id="card6" className="more" aria-hidden="true" />
                            <div className="content">
                                <div className="front" style={{
                                    backgroundImage: `url(${tcetkiosk})`
                                }}>
                                    <div className="inner">
                                        <h2><a href="https://github.com/shreeyag11/tcet-kiosk-system/" alt="Kiosk System">Kiosk System</a></h2>
                                        <label htmlFor="card6" className="button" aria-hidden="true">
                                            Details
                        </label>
                                    </div>
                                </div>
                                <div className="back">
                                    <div className="inner">
                                        <div className="info">
                                            <b> Tags: Android Studio, Google Firebase, Java</b>
                                        </div>
                                        <div className="description">
                                            <p>This kiosk system is an android application which is used to automate the generation of letters for an institute which can be used by anyone having this application to interact with the administration using an appropriate format.
                                            It will automate the entire letter generation and tracking process,
                                            eliminating the manual efforts and thus saving time.
                                    </p>
                                        </div>
                                        <div className="project-header">
                                            <div className="location" style={{ display: "flex", marginBottom: "2rem" }}>
                                                {/* eslint-disable-next-line */}
                                                <a href="https://github.com/shreeyag11/tcet-kiosk-system/" target="_blank" rel="noopener noreferrer" style={{ color: "#000000", fontSize: "30px" }}><i className="fa fa-github" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                        <label htmlFor="card6" className="button return" aria-hidden="true">
                                            <i className="fa fa-arrow-left"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pcard">
                            <input type="checkbox" id="card7" className="more" aria-hidden="true" />
                            <div className="content">
                                <div className="front" style={{
                                    backgroundImage: `url(${smartcity})`
                                }}>
                                    <div className="inner">
                                        <h2><a href="https://github.com/shreeyag11/smart-city/" alt="Smart City">Smart City</a></h2>
                                        <label htmlFor="card7" className="button" aria-hidden="true">
                                            Details
                        </label>
                                    </div>
                                </div>
                                <div className="back">
                                    <div className="inner">
                                        <div className="info">
                                            <b> Tags: Android Studio, Google Firebase, Java</b>
                                        </div>
                                        <div className="description">
                                            <p>Participated in Inter-collegiate project competition on Smart city.
                                            This is an android application which covers the Society Management, Women Safety, Health and Empowerment,
                                            and a Climate Control and Disaster Management module.
                                            We consider this application as an all in one
                                            Smart City working application for the people. The aim of this project
                                            is to have a hassle free all in one application to help people live their life
                                            a better way.
                                    </p>
                                        </div>
                                        <div className="project-header">
                                            <div className="location" style={{ display: "flex", marginBottom: "2rem" }}>
                                                {/* eslint-disable-next-line */}
                                                <a href="https://github.com/shreeyag11/smart-city/" target="_blank" rel="noopener noreferrer" style={{ color: "#000000", fontSize: "30px" }}><i className="fa fa-github" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                        <label htmlFor="card7" className="button return" aria-hidden="true">
                                            <i className="fa fa-arrow-left"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pcard">
                            <input type="checkbox" id="card8" className="more" aria-hidden="true" />
                            <div className="content">
                                <div className="front" style={{
                                    backgroundImage: `url(${leetcode})`
                                }}>
                                    <div className="inner">
                                        <h2><a href="https://leetcode.com/shreeya1234/" alt="Leetcode">Leetcode</a></h2>
                                        <label htmlFor="card8" className="button" aria-hidden="true">
                                            Details
                        </label>
                                    </div>
                                </div>
                                <div className="back">
                                    <div className="inner">
                                        <div className="info">
                                            <b> Tags: Java, Algorithm and Data Structures</b>
                                        </div>
                                        <div className="description">
                                            <p>Leetcode is a coding platform where I test and enhance my coding skills.
                                            My preferred language of coding is Java. However, I am also comfortable using
                                            Python and JavaScript.
                                    </p>
                                        </div>
                                        <div className="project-header">
                                            <div className="location" style={{ display: "flex", marginBottom: "2rem" }}>
                                                {/* eslint-disable-next-line */}
                                                <a href="https://leetcode.com/shreeya1234/" target="_blank" rel="noopener noreferrer" style={{ color: "#000000", fontSize: "30px" }}><i className="fa fa-external-link" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                        <label htmlFor="card8" className="button return" aria-hidden="true">
                                            <i className="fa fa-arrow-left"></i>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Project
