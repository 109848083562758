import React from 'react'
import meImg from '../../assets/images/img.jpg';
import "./about.css"

function About() {

    return (

        <div className="about" id="about" >
            <div className="in-middle-about">
                <h3 className="section-title">ABOUT ME</h3>
                <div className="centered line"></div>
                <div className="about-main">
                    <div className="card-text">
                        Hello! I am Shreeya, a software engineer based in Charlotte, NC.<br />
                            Right after completing my Bachelor of Engineering in
                            Information Technology at <a href="https://mu.ac.in/" target="blank">University of Mumbai</a>, I was delighted to pursue
                            Master of Science in Computer Science at
                            <a href="https://www.uncc.edu/" target="blank"> University of North Carolina at Charlotte</a>.<br />
                                    Currently, I am working as a Full Stack Developer Intern at <a href="https://kanexon.com/" target="blank">Kanexon</a> in which I am using Java, Google Firebase, JavaScript, Angular.js as technology stack. <br />
                                    Furthermore, to get more knowledge in my area of expertise and with women of similar interests I will be attending <a href="https://ghc.anitab.org/" target="blank">Grace hopper Celebration 2020</a>.<br />
                                    Apart from this, I like to learn new technologies, design and develop websites as well as
                                    mobile applications. I have hands-on experience in developing
                                    scalable cross browser web and mobile applications.<br />
                                    Further describing me, I believe I am a problem solver and I don't fear
                                    facing real world challenges and preferrably solving them. I am an
                                    enthusiastic programmer who understands the importance of simple yet optimized solution. <br />
                                    I enjoy spending my free time cooking food and watching television.<br />
                                    Besides, I am a dog lover and that further upgrades my portfolio. <span role="img" aria-label="wink">&#128521;</span><br />
                    </div>
                    <div className="image-align">
                        <img src={meImg} alt="Shreeya Gupta" className="in-middle" />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default About