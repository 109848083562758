import React, { Component } from 'react';
import Home from './components/Home';
import './assets/css/App.css'
import NavBar from './components/Navbar/NavBar';
import Footer from './components/Footer';
import Experience from './components/Experience/Experience';
import Project from './components/Projects/Project';
import About from './components/About/About';
import Education from './components/Education/Education';
import Skills from './components/Skills/Skills';
import Contact from './components/Contact';
import SideDrawer from './components/Navbar/SideDrawer/SideDrawer';
import Backdrop from './components/Navbar/Backdrop/Backdrop';
import Particle from './components/ParticleJs/Particle'
import Others from './components/Others/Others';

class App extends Component {
  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  }

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };
  render() {
    let backdrop;
    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />
    }

    return (
      <div className="App" style={{ height: "100%" }}>
        <Particle
          params={{
            particles: {
              number: {
                value: 50,
              },
              size: {
                value: 3,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: "repulse",
                },
              },
            },
          }}
        />
        <div className="main" >
          <NavBar drawerClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />
          {backdrop}
          <Home />
        </div>
        <About />
        <Project />
        <Education />
        <Experience />
        <Skills />
        <Others />
        <Contact />
        <Footer />
      </div >
    );
  }
}

export default App;
