import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import './Navbar.css'

const styles = (theme) => ({
    header: {
        position: "static",
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            minHeight: 0,
        },
        [theme.breakpoints.down("sm")]: {
            minHeight: "30px",
        },
    },
    headerBar: {
        backgroundColor: "blue",
        boxShadow: "none",
    },
    headerBarFixed: {
        background: "linear-gradient( to bottom, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100% ), linear-gradient(135deg, #670d10 0%, #092756 100%);",
        boxShadow:
            "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);",
    },
    headerBackground: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    flex: {
        flex: 1,
    },
    horizontalNavigation: {
        flex: 1,
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    verticalNavigation: {
        flex: 1,
        minWidth: 220,
        color: "#03e9f4",
        textDecoration: "none",
        background: "linear-gradient( to bottom, rgba(57, 173, 219, 0.25) 0%, rgba(42, 60, 87, 0.4) 100% ), linear-gradient(135deg, #670d10 0%, #092756 100%);",
    },
    hamburger: {
        float: "right",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    removeLinkUnderline: {
        textDecoration: "none",
        color: "#d6d7e1",
    },
});

export class Header extends Component {
    state = {
        position: "static",
        navigationItemClass: true,
        isDrawerVisible: false,
    };

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll.bind(this));
        this.handleScroll();
    }

    handleScroll() {
        if (ReactDOM.findDOMNode(this).getBoundingClientRect) {
            let rect = ReactDOM.findDOMNode(this).getBoundingClientRect();
            let position = "static";
            let navigationItemClass = false;
            if (rect) {
                if ((rect.y || rect.top) < -45) {
                    position = "fixed";
                    navigationItemClass = true;
                }
                this.setState({
                    position,
                    navigationItemClass,
                });
            }
        }
    }

    _showDrawer() {
        this.setState({
            isDrawerVisible: true,
        });
    }

    _hideDrawer() {
        this.setState({
            isDrawerVisible: false,
        });
    }

    render() {
        let displayDrawer;
        if (this.state.navigationItemClass) {
            displayDrawer = "block";
        } else {
            displayDrawer = "none";
        }

        const classes = this.props.classes;
        const { position, navigationItemClass } = this.state;
        const headerClass = classNames({
            [classes.headerBar]: true,
            [classes.headerBarFixed]: position === "fixed",
        });
        return (
            <div className={classes.header} style={{ display: "flex" }}>
                <AppBar
                    position="fixed"
                    className={headerClass}
                    style={{ display: displayDrawer }}
                >
                    <IconButton
                        className={classes.hamburger}
                        onClick={this._showDrawer.bind(this)}
                        aria-label="Menu"
                        style={{ color: "#03e9f4" }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.flex}></div>
                    <Grid
                        className={classes.horizontalNavigation}
                        container
                        spacing={0}
                        direction="row"
                    >
                        <div style={{ display: "flex" }}>

                        </div>
                        <div style={{ flex: "1" }} />
                        <div className="nav">
                            <ul>
                                <li><a href="#about" className="nav-link">About</a></li>
                                <li><a href="#projects" className="nav-link">Projects</a></li>
                                <li><a href="#education" className="nav-link">Academics</a></li>
                                <li><a href="#experience" className="nav-link">Experience</a></li>
                                <li><a href="#skills" className="nav-link">Skills</a></li>
                                <li><a href="#others" className="nav-link">Activities</a></li>
                                <li><a href="#contact" className="nav-link">Contact</a></li>

                                <li><a href="https://shreeyag11.github.io/resume/" target="_blank" rel="noopener noreferrer" className="btn-resume"> <i className="fa fa-download" aria-hidden="true"></i> Resume</a></li>
                            </ul>
                        </div>
                    </Grid>
                </AppBar>
                <Drawer
                    anchor="right"
                    open={this.state.isDrawerVisible}
                    onClose={() => this._hideDrawer()}
                >
                    <Grid
                        className={classes.verticalNavigation}
                        container
                        alignItems="center"
                        justify="center"
                        spacing={0}
                        direction="column"
                    >
                        <ul
                            type="none"
                            style={{
                                fontSize: "18px",
                                padding: "0",
                                textAlign: "center"
                            }}
                        >

                            <li>
                                <a href="#about" className={classes.removeLinkUnderline}>
                                    <b>About</b>
                                </a>
                            </li>
                            <br></br>
                            <li>
                                <a href="#projects" className={classes.removeLinkUnderline}>
                                    <b>Projects</b>
                                </a>
                            </li>
                            <br></br>
                            <li>
                                <a href="#education" className={classes.removeLinkUnderline}>
                                    <b>Academics</b>
                                </a>
                            </li>
                            <br></br>
                            <li>
                                <a href="#experience" className={classes.removeLinkUnderline}>
                                    <b>Experience</b>
                                </a>
                            </li>
                            <br></br>
                            <li>
                                <a href="#skills" className={classes.removeLinkUnderline}>
                                    <b>Skills</b>
                                </a>
                            </li>
                            <br></br>
                            <li>
                                <a href="#others" className={classes.removeLinkUnderline}>
                                    <b>Activities</b>
                                </a>
                            </li>
                            <br></br>

                            <li>
                                <a href="#contact" className={classes.removeLinkUnderline}>
                                    <b>Contact</b>
                                </a>
                            </li>
                            <br></br>

                            <li>
                                <a
                                    href="https://shreeyag11.github.io/resume/"
                                    className={classes.removeLinkUnderline}
                                >
                                    <b><i className="fa fa-download" aria-hidden="true"></i>    Resume</b>
                                </a>
                            </li>
                        </ul>
                    </Grid>
                </Drawer>
            </div>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Header);