import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SocialSites from './Social/SocialSites';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            width: "18rem",
            fontSize: "12.5px",
        },
    },
}));

const Home = () => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <div className="contents">
                <div className="in-middle_home">
                    <div className="line1">
                        <b>Shreeya Gupta</b>
                    </div>
                    <hr className="hrTag" />
                    <div className="line2">
                        Software Engineer
                    </div>
                    <br />
                    <div className="line3">
                        Specializing in building and designing<br /> exceptional websites &amp; applications.<br />
                    </div>
                    <div className={classes.root}>
                        <form method="post" action="mailto:shreeyag11@gmail.com" >
                            <input type="submit" value="Get in Touch" className="btn-resume" />
                        </form>
                    </div>
                    <SocialSites />
                </div>
            </div>

        </React.Fragment >
    );
}

export default Home;