import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto"
    },
    panel: {
        backgroundColor: "#adb0c4",
        border: "4px solid #d6d7e1"
    },
    icon: {
        color: "#072142",
        fontSize: "18px",
        fontFamily: "Espuma Pro Medium",
    },
    date: {
        fontSize: "15px",
        color: "#072142",
        marginRight: 0,
        marginLeft: "auto",
        fontFamily: "Espuma Pro Medium",

    },
    heading: {
        textAlign: "center",
    },
    content: {
        fontSize: "18px",
        fontFamily: "Espuma Pro Medium",
        backgroundColor: "#d6d7e1",

    }
}));

export default function Experience() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState("panel4");

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className="experience" id="experience">
            <div className="in-middle_experience">
                <h3 className="section-title">Work Experience</h3>
                <div className="centered line"></div>

                <div className={classes.root}>
                    <ExpansionPanel
                        expanded={expanded === "panel4"}
                        onChange={handleChange("panel4")}
                    >
                        <ExpansionPanelSummary
                            className={classes.panel}
                            expandIcon={<ExpandMoreIcon className={classes.icon} />}
                        >
                            <Typography variant="h6" className={classes.icon}>
                                Kanexon Inc.
                                </Typography>
                            <Typography className={classes.date}>July 2020 - Present</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.content}>
                            <div>
                                <b>Position:</b> Full - Stack Developer Intern<br /><br />
                                <b>Responsibilities:</b><br />
                                &#62; Working on developing a web-based social media application that centers around on connecting individuals and communities working towards spreading positivity and happiness.
                                 <br />   &#62; Developed user specific profile pages by writing Java code to create a functioning REST API such that it fetches data from Google Firebase database and displayed the fetched data on the User Interface using Angular.js. Used Git for version control.
                                 <br />   &#62; Created API to upload multiple media with respect to every user in separate bucket in Firebase storage cloud to  retrive and display the media on the webpage.
                                <br /><br />
                                <b>Technology Stack:</b>
                                <ul type="disk" class="tech">
                                    <li>Java</li>
                                    <li>JavaScript</li>
                                    <li>Google Firebase</li>
                                    <li>REST API</li>
                                    <li>Git</li>                                    <li>Angular.js</li>
                                </ul>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <br />
                    <ExpansionPanel
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                    >
                        <ExpansionPanelSummary
                            className={classes.panel}
                            expandIcon={<ExpandMoreIcon className={classes.icon} />}
                        >
                            <Typography variant="h6" className={classes.icon}>
                                University of North Carolina at Charlotte
                                </Typography>
                            <Typography className={classes.date}>May 2020 - July 2020</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.content}>
                            <div>
                                <b>Position:</b> Graduate Teaching Assistant<br /><br />
                                <b>Course:</b> ITCS 3134<br /><br />
                                <b>Responsibilities:</b><br />
                                &#62; Responsibilities includes creation and maintenance of web application
                                    software using Vue.js.<br />
                                    &#62; This web application was used throughout the course.<br />
                                    &#62; Along with grading coursework
                                     of the students, conducting class activities, and addressing student issues during
                                      office hours.
                                      <br /> <br />
                                <b>Technology Stack:</b>
                                <ul type="disk" class="tech">
                                    <li>Vue.js</li>
                                    <li>Vuex</li>
                                    <li>Git</li>
                                    <li>Webpack</li>
                                    <li>Rust</li>
                                    <li>webAssembly</li>
                                    <li>Python</li>
                                </ul>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <br />
                    <ExpansionPanel
                        expanded={expanded === "panel2"}
                        onChange={handleChange("panel2")}
                    >
                        <ExpansionPanelSummary
                            className={classes.panel}
                            expandIcon={<ExpandMoreIcon className={classes.icon} />}
                        >
                            <Typography variant="h6" className={classes.icon}>
                                Ganyt E-commerce Solution Pvt Ltd.
                                 </Typography>
                            <Typography className={classes.date}>
                                June 2018- August 2018
                                </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.content}>
                            <div>
                                <b>Position:</b> Web Development Intern<br /><br />
                                <b>Responsibilities:</b><br />
                                &#62; Designed and developed user-friendly interface to easily navigate the web application in HTML and JavaScript using ReactJS.
                                 <br />   &#62; Participation in fundamental activities like gathering business requirements, collection, analysis, design, development, and testing.
                                 <br />   &#62; Developed the components in React using CSS and Bootstrap to provide design view in the web page.
                                 <br />   &#62; Developed SQL Queries, Stored Procedures, function, tables and data connectivity and data integration for the application.<br /><br />
                                <b>Technology Stack:</b>
                                <ul type="disk" class="tech">
                                    <li>HTML5/CSS</li>
                                    <li>JavaScript</li>
                                    <li>React.js</li>
                                    <li>Bootstrap</li>
                                    <li>Git</li>
                                    <li>MySQL</li>
                                </ul>

                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <br />
                    <ExpansionPanel
                        expanded={expanded === "panel3"}
                        onChange={handleChange("panel3")}
                    >
                        <ExpansionPanelSummary
                            className={classes.panel}
                            expandIcon={<ExpandMoreIcon className={classes.icon} />}
                        >
                            <Typography variant="h6" className={classes.icon}>
                                Sammey Inc.
                              </Typography>
                            <Typography className={classes.date}>
                                June 2017 - July 2017
                             </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.content}>
                            <div>
                                <b>Position:</b> Web Development Intern<br /><br />
                                <b>Responsibilities:</b><br />
                                &#62; Drafted SEO friendly content for website which lead to more lead generation to the clients which helped excel in various online marketing methods.
                                   <br /> &#62; Designed front-end User Interface for web applications as per the requirements of client, with regular updates and modifications as and when required.
                                   <br /> &#62; Developed and formulated a robust and strong backend scripting which not only led to streamline flow of User Interface but also functioned to have a full control to the user making it highly dynamic and more functional in nature.<br /><br />
                                <b>Technology Stack:</b>
                                <ul type="disk" class="tech">
                                    <li>JavaScript</li>
                                    <li>MySQL</li>
                                    <li>HTML5/CSS</li>
                                    <li>Git</li>
                                    <li>Bootstrap</li>
                                </ul>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            </div>
        </div >

    );
}