import React, { Component } from "react";
import "./Particle.css";
import Particles from "react-particles-js";

class ParticlesBackground extends Component {
    render() {
        return (
            <div>
                <div>
                    <Particles
                        params={{
                            particles: {
                                number: {
                                    value: 90,
                                    density: {
                                        enable: true,
                                        value_area: 1000,
                                    },
                                },
                                color: {
                                    value: "#03e9f4",
                                },
                                shape: {
                                    type: "circle",
                                    stroke: {
                                        width: 2,
                                        color: "#d6d7e1",
                                        opacity: 0.1
                                    },
                                    polygon: {
                                        nb_sides: 5,
                                    },
                                    image: {
                                        src: "img/github.svg",
                                        width: 100,
                                        height: 100,
                                    },
                                },
                                opacity: {
                                    value: 0.5,
                                    random: false,
                                    anim: {
                                        enable: false,
                                        speed: 1,
                                        opacity_min: 0.1,
                                        sync: false,
                                    },
                                },
                                size: {
                                    value: 3,
                                    random: true,
                                    anim: {
                                        enable: false,
                                        speed: 40,
                                        size_min: 0.1,
                                        sync: false,
                                    },
                                },
                                line_linked: {
                                    enable: true,
                                    distance: 150,
                                    color: "#d6d7e1",
                                    opacity: 0,
                                    width: 1,
                                },
                                move: {
                                    enable: true,
                                    speed: 2,
                                    direction: "none",
                                    random: false,
                                    straight: false,
                                    out_mode: "out",
                                    bounce: false,
                                    attract: {
                                        enable: false,
                                        rotateX: 600,
                                        rotateY: 1200,
                                    },
                                },
                            },
                            interactivity: {
                                detect_on: "canvas",
                                events: {
                                    onhover: {
                                        enable: false,
                                        mode: "repulse",
                                    },
                                    onclick: {
                                        enable: true,
                                        mode: "push",
                                    },
                                    resize: true,
                                },
                                modes: {
                                    grab: {
                                        distance: 400,
                                        line_linked: {
                                            opacity: 1,
                                        },
                                    },
                                    bubble: {
                                        distance: 400,
                                        size: 40,
                                        duration: 2,
                                        opacity: 8,
                                        speed: 3,
                                    },
                                    repulse: {
                                        distance: 200,
                                        duration: 0.4,
                                    },
                                    push: {
                                        particles_nb: 4,
                                    },
                                    remove: {
                                        particles_nb: 2,
                                    },
                                },
                            },
                            retina_detect: true,
                        }}
                        style={{
                            width: "100%",
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default ParticlesBackground;