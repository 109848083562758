import React from 'react'

function Others() {

    return (

        <div className="about" id="others" >
            <div className="in-middle-about">
                <h3 className="section-title">Other things that interests me..</h3>
                <div className="centered line"></div>

                <div className="others">
                    <ul className="tech">
                        <li><b><a href="https://ghc.anitab.org/" target="blank">Grace Hopper Celebration: </a></b>Excited to attend Grace Hopper Conference 2020 to celebrate women in tech.</li><br />
                        <li><b><a href="https://www.womentech.net/en-us" target="blank">WomenTech Network: </a></b>Worked as a Global Ambassador for WomenTech Network uniting 100,000 women in tech to discuss things that matter.</li><br />
                        <li><b><a href="https://samarpansevatrust.org/" target="blank">The Samaprpan Seva Charitable Trust: </a></b>Volunteered for spreading awareness about donating eyes and organs for visually impaired and disabled people.</li><br />
                        <li><b><a href="https://intcce.org/green/" target="blank">Climate Change:</a></b> Certificate of excellence by International center for culture and education (ICCE) for Green Revolution global certification program.      </li>
                    </ul>
                </div>

            </div>
        </div>

    )
}

export default Others